@import '../../common/variables.css';
@import '../../common/mixins/font.css';
@import '../../common/mixins/text.css';

.price {
  white-space: nowrap;
  @mixin ml-font-family;
  @mixin ml-font-smoothing;
}

.accepted {
  color: var(--color-accepted);
}

.secondary {
  color: var(--color-text-secondary);
}

.strikethrough {
  text-decoration: line-through;
}
